// colors
$white: #fff;

//--temno-siva: #707070;
//--srednje-siva: #BFBFBF;
//--svetlo-modra: #ADCEF7;
//--bela: #FFFFFF;
//--temno-modra: #4094FF;
//--živila: #C3D8E3;
//--temno-zelena: #8ED197;
//--podlaga-drsnikov-količin: #F2F2F2;
//--svetlo-zelena: #B6E6B1;
//--podlaga-dni-koledarja: #D4E6FF;
//--podlaga-menuja: #989898;
//--mascobe: #AEE0F2;
//--ogljikovi-hidrati: #AAD486;
//--beljakovine: #FBD367;
//--kalorije: #E0B3D8;
//--sladkor: #F0C4AF;
//--sol: #EBCDAB;
//--mascoba: #EBD6AB;
//--svetlo-siva: #E6E6E6;
//--cena: #B6B9DE;

// gray
$gray-dark: #707070;
$gray-darker: #303030;
$menu-bg: #989898;
$gray-back-dark: #A1A1A1;
$gray-medium: #BFBFBF;
$gray-light: #E6E6E6;
$gray-slider-bg: #F2F2F2;
$gray-page: #F7F7F7;


// blue
$blue-text: #5061B8;
$blue-back: #C6DFFF;
$blue-light: #ABB3DD;

$menu-bare: #D3D7ED;
$menu-invalid: #A1CAFF;
$menu-completed: #B6E6B1;
$menu-current: #F2F2F2;

// pisava, elementi
$green-text: #00B919;

// ploskve
$green-back: #7EDB8B;

$green-light: #B6E6B1;

// pisava, elementi
$red-text: #E33636;

// ploskve
$red-back: #F9A7A7;

$red-medium: #ffa3a3;

$red-light: #ffd8d8;

// OGLJIKOVI HIDRATI
$chot: #B3CB83;

// BELJAKOVINE
$prot: #FBBB67;

// MAŠČOBE
$fat: #84ACBA;

// KALORIJE
$energy: #CE9FC5;

// SLADKOR
$sugar: #F0C4AF;

// SOL
$salt: #EBCDAB;

// NASIČENE MAŠČOBE
$fasat: #EBD6AB;

// ŽIVILA
$cyan: #C3D8E3;


// PRIDELAVA
// $pridelava: #8BC999;


$grid-breakpoints: (
  // xs: 0,
  // sm: 576px,
  // md: 768px,
  // lg: 992px,
  xl: 0, // 1200px,
  xxl: 1600px
) !default;

$container-max-widths: (
  // sm: 540px,
  // md: 720px,
  // lg: 960px,
  xl: 1140px,
  xxl: 1510px
) !default;

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@function breakpoint-min($name) {
  $min: map-get($grid-breakpoints, $name);
  @return if($min !=0, $min, null);
}

@mixin media-breakpoint-up($name) {
  $min: breakpoint-min($name);

  @if $min {
    @media (min-width: $min) {
      @content;
    }
  }

  @else {
    @content;
  }
}

@mixin font-huge($color: inherit) {
  color: $color;
  font-size: 36px;
  font-weight: 400;
  @include media-breakpoint-up(xl) {
    font-size: 45px;
  }
  @include media-breakpoint-up(xxl) {
    font-size: 60px;
  }
}

@mixin font-large($color: inherit) {
  color: $color;
  font-size: 18px;
  font-weight: 300;
  @include media-breakpoint-up(xl) {
    font-size: 24px;
  }
  @include media-breakpoint-up(xxl) {
    font-size: 40px;
  }
}

@mixin font-normal($color: inherit) {
  color: $color;
  font-size: 12px;
  font-weight: 500;
  @include media-breakpoint-up(xl) {
    font-size: 13px;
  }
  @include media-breakpoint-up(xxl) {
    font-size: 17px;
  }
}

@mixin font-small($color: inherit) {
  color: $color;
  font-size: 10px;
  font-weight: 900;
  letter-spacing: 0.3px;
  @include media-breakpoint-up(xxl) {
    font-size: 11px;
  }
}

@mixin font-bold {
  font-weight: 900;
}

@mixin font-label {
  font-weight: 900;
  font-size: 12px;
  @include media-breakpoint-up(xl) {
    font-size: 13px;
  }
  @include media-breakpoint-up(xxl) {
    font-size: 17px;
  }
}

$categories: "1", "2", "3", "5", "6", "9", "10", "11";

