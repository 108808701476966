@import "variables";
@import "mixins";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&family=Roboto:wght@300;400;500;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');
@import "icons";

body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  @include font-normal($gray-dark);
  overflow-x:hidden;
}

header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}

app-layout {
  padding-top: 50px;
  box-sizing: border-box;
}

footer {
  background: #D9D9D9;
}

button:not(:disabled) {
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.search-box {
  position: relative;

  input {
    @include font-normal;
    display: block;
    width: 100%;
    padding: 15px 60px 15px 20px;
    box-sizing: border-box;
    color: $blue-text;
    border-radius: 25px;
    border: 3px solid $blue-text;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: $blue-light;
    }
  }

  &::after {
    content: "";
    position: absolute;
    width: 40px;
    height: 40px;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    @include svg-icon("search.svg", $blue-text, 30px auto);
  }
}

.btn-full-circle-plus {
  @include full-circle-btn("full-plus.svg", $blue-text);
  &.active {
    @include full-circle-btn("full-plus.svg", $gray-dark);
  }
}

.btn-circle-plus {
  @include circle-btn("plus.svg", $blue-text);
  &.active {
    @include circle-btn("plus.svg", $gray-dark);
  }
}

.btn-circle-import {
  @include circle-btn("import.svg", $blue-text);
  &.active {
    @include circle-btn("import.svg", $gray-dark);
  }
}

.btn-full-circle-import {
  @include full-circle-btn("full-import.svg", $blue-text);
  &.active {
    @include full-circle-btn("full-import.svg", $gray-dark);
  }
}

.btn-circle-accept {
  @include circle-btn("accept.svg", $blue-text);
  &.active {
    @include circle-btn("accept.svg", $gray-dark);
  }
}

.btn-circle-import {
  @include circle-btn("import.svg", $blue-text);
  &.active {
    @include circle-btn("import.svg", $gray-dark);
  }
}

.btn-circle-search {
  @include circle-btn("search.svg", $blue-text);
  &.active {
    @include circle-btn("search.svg", $gray-dark);
  }
}

.btn-full-circle-search {
  @include full-circle-btn("full-search.svg", $blue-text);
  &.active {
    @include full-circle-btn("full-search.svg", $gray-dark);
  }
}

.btn-clear-plus {
  @include clear-btn("plus.svg", $blue-text);
  &.active {
    @include clear-btn("plus.svg", $gray-dark);
  }
}

.btn-clear-close {
  @include clear-btn("close.svg", $blue-light);
  &.active {
    @include clear-btn("close.svg", $gray-dark);
  }
}

.btn-close {
  width: 40px;
  height: 40px;
  display: block;
  border: none;
  background: transparent;
  padding: 0;
  margin: 0;
  @include svg-icon('close.svg', $gray-dark);
}

.btn-cancel {
  @include action-btn('close.svg');
}

.btn-accept {
  @include action-btn('accept.svg');
}

.btn-full-accept {
  @include action-btn('full-accept.svg');
}

.btn-full-accept-large {
  @include action-btn('full-accept.svg', 65px);
  font-size: 13px !important;
}

.btn-plus {
  @include action-btn('plus.svg');
}

.btn-add-horizontal {
  position: relative;
  padding-left: 25px;
  text-transform: uppercase;
  @include font-small($blue-text);
  font-weight: 900;
  background: none;
  margin: 0;
  border: 0;

  &:before {
    content: "";
    @include svg-icon('plus-inline.svg', $blue-text);
    position: absolute;
    width: 20px;
    height: 20px;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
}

.btn-add-horizontal-large {
  @include reset-button;
  display: flex;
  align-items: center;
  &::before {
    content: "";
    @include svg-icon("plus-full.svg", $blue-text);
    width: 50px;
    height: 50px;
  }

  span {
    text-transform: uppercase;
    @include font-small($blue-text);
    margin-left: 7px;
    text-align: left;
  }
}

.btn-add-horizontal-large2 {
  @include reset-button;
  display: flex;
  align-items: center;
  &::before {
    content: "";
    @include svg-icon("circle-plus.svg", $blue-text);
    width: 50px;
    height: 50px;
  }

  span {
    text-transform: uppercase;
    @include font-small($blue-text);
    margin-left: 7px;
    text-align: left;
  }
}

.btn-add {
  @include action-btn('circle-plus.svg');
}


.btn-copy {
  @include action-btn('copy.svg');
}

.btn-paste {
  @include action-btn('paste.svg');
}

.btn-select {
  @include action-btn('select.svg');
}

.btn-report {
  @include reset-button;
  line-height: 14px;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 900;
  color: $blue-light;
  position: relative;
  padding: 0 0 0 30px;
  text-transform: uppercase;
  text-align: left;
  text-decoration: none;
  &:before {
    content: "";
    @include size(20px);
    @include svg-icon('print.svg', $blue-light);
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

.btn-shopping-list {
  @include reset-button;
  line-height: 14px;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 900;
  color: $blue-light;
  position: relative;
  padding: 0 0 0 30px;
  text-transform: uppercase;
  text-align: left;
  text-decoration: none;
  &:before {
    content: "";
    @include size(20px);
    @include svg-icon('preparation_normal.svg', $blue-light);
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

.btn-menu-month {
  @include action-btn("grid.svg");
}

.help-tooltip {
  @include svg-icon('help-full.svg', $gray-light);
  width: 18px;
  height: 18px;
  &:hover {
    @include svg-icon('help-full.svg', $gray-dark);
  }
}

.header-date-picker {
  min-width: 9.5em;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  @include font-huge(inherit);
  line-height: 1em;
  white-space: nowrap;
  flex-shrink: 0;

  .date--prev, .date--next, button {
    @include size(30px);
  }

  .date--prev {
    @include svg-icon('arrow-left.svg', $blue-light);
    position: absolute;
    left: -45px;
  }

  .date--next {
    @include svg-icon('arrow-right.svg', $blue-light);
    margin-left: auto;
    position: relative;
    left: 20px;
  }

  button {
    margin-left: 20px;
  }
}

.search-filter {
  display: grid;
  grid-template-columns: auto 1fr auto 1fr auto 1fr auto 1fr auto;
  align-items: center;
  @include font-small($blue-text);
  margin-bottom: 30px;

  .form-group {
    margin-bottom: 0;
    select {
      color: inherit;
    }
  }
}

.search-filter--all {
  display: block;
  position: relative;
  background: none;
  border: 0;
  margin: 0 10px 0 0;
  padding: 0 0 0 30px;
  @include font-normal($gray-dark);
  font-weight: 900;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    @include svg-icon("filter.svg", $gray-dark);
    width: 23px;
    height: 23px;
  }
}

.search-filter--all-inactive {
  color: $blue-text;
  &:before {
    @include svg-icon("filter.svg", $blue-text);
  }
}

.clear-filter {
  margin: 3px 5px 0 5px;
  background: transparent;
  padding: 0;
  border: 0;
  @include svg-icon("close.svg", $gray-dark);
  width: 1.5em;
  height: 1.5em;
  font-size: inherit;
  visibility: hidden;
  &.visible {
    visibility: visible;
  }
}

.hidden {
  display: none !important;
}

.invisible {
  visibility: hidden;
}

.default-tooltip {
  text-align: left !important;
}

.user-layout-form {
  width: 100%;

  input {
    box-sizing: border-box;
    display: block;
    width: 100%;
    height: 50px;
    border-radius: 25px;
    background: $white;
    border: 0;
    text-align: left;
    font-weight: bold;
    &::placeholder {
      color: $gray-light;
    }
    color: $gray-dark;
    font-size: 20px;
    letter-spacing: 2.4px;
    margin-bottom: 12px;
    padding: 0 20px;

  }

  button[type=submit] {
    @include reset-button;
    display: block;
    font-size: 25px;
    margin: 25px auto 30px auto;
    color: #D6D6D6;
    i::before {
      display: block;
    }
    height: 45px;
    border: 5px solid #D6D6D6;
    border-radius: 25px;
    width: 110px;
    text-align: center;
  }

  .links {
    a {
      font-weight: 600;
      text-decoration: none;
      color: $gray-medium;
      display: block;
      text-align: center;
    }
  }

  .instr {
    margin-top: -20px;
    margin-bottom: 20px;
    text-align: center;
  }
}

.clickable {
  cursor: pointer;
}

.home-container {
  @include main-component-layout;
}

.home-title {
  margin: 50px 0 45px 0;
  font-size: 55px;
  font-weight: 400;
}

.home-subtitle {
  margin: 40px 0 20px 0;
  font-weight: 300;
  font-size: 39px;
  line-height: 1em;
}

.home-content {
  font-weight: 400;
  font-size: 14px;
  line-height: 27px;
  max-width: 650px;

  a {
    font-weight: 700;
    color: $blue-text;
    text-decoration: none;
  }
}

table.generic {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  margin-bottom: 30px;

  th, td {
    padding: 10px;
    border: 2px solid $gray-dark;
    text-align: left;
  }

  th {
    background: $gray-slider-bg;
  }

  td {
    background: $white;
  }
}

