@font-face {
    font-family: "sauce-pan";
    src: url("~src/assets/fonts/sauce-pan.ttf?f87953f860116c861cca586909f183ac") format("truetype"),
url("~src/assets/fonts/sauce-pan.woff?f87953f860116c861cca586909f183ac") format("woff"),
url("~src/assets/fonts/sauce-pan.woff2?f87953f860116c861cca586909f183ac") format("woff2");
}

i[class^="icon-"]:before, i[class*=" icon-"]:before {
    font-family: sauce-pan !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-absence_normal:before {
    content: "\f101";
}
.icon-absence_over:before {
    content: "\f102";
}
.icon-absence_thin:before {
    content: "\f103";
}
.icon-accept_circle:before {
    content: "\f104";
}
.icon-accept_small:before {
    content: "\f105";
}
.icon-accept:before {
    content: "\f106";
}
.icon-arrow-left:before {
    content: "\f107";
}
.icon-arrow-right:before {
    content: "\f108";
}
.icon-calendar:before {
    content: "\f109";
}
.icon-category-1:before {
    content: "\f10a";
}
.icon-category-10:before {
    content: "\f10b";
}
.icon-category-11:before {
    content: "\f10c";
}
.icon-category-2:before {
    content: "\f10d";
}
.icon-category-3:before {
    content: "\f10e";
}
.icon-category-5:before {
    content: "\f10f";
}
.icon-category-6:before {
    content: "\f110";
}
.icon-category-9:before {
    content: "\f111";
}
.icon-checkbox:before {
    content: "\f112";
}
.icon-circle-minus:before {
    content: "\f113";
}
.icon-circle-plus:before {
    content: "\f114";
}
.icon-close-small:before {
    content: "\f115";
}
.icon-close:before {
    content: "\f116";
}
.icon-copy_circle:before {
    content: "\f117";
}
.icon-copy_full:before {
    content: "\f118";
}
.icon-copy:before {
    content: "\f119";
}
.icon-dot:before {
    content: "\f11a";
}
.icon-down-small:before {
    content: "\f11b";
}
.icon-down:before {
    content: "\f11c";
}
.icon-exclamation:before {
    content: "\f11d";
}
.icon-filter:before {
    content: "\f11e";
}
.icon-full-accept:before {
    content: "\f11f";
}
.icon-full-import:before {
    content: "\f120";
}
.icon-full-minus:before {
    content: "\f121";
}
.icon-full-plus:before {
    content: "\f122";
}
.icon-full-search:before {
    content: "\f123";
}
.icon-grid:before {
    content: "\f124";
}
.icon-groups_normal:before {
    content: "\f125";
}
.icon-groups_over:before {
    content: "\f126";
}
.icon-groups_thin:before {
    content: "\f127";
}
.icon-help-full:before {
    content: "\f128";
}
.icon-help:before {
    content: "\f129";
}
.icon-import:before {
    content: "\f12a";
}
.icon-info:before {
    content: "\f12b";
}
.icon-invalid:before {
    content: "\f12c";
}
.icon-left-small:before {
    content: "\f12d";
}
.icon-left:before {
    content: "\f12e";
}
.icon-menu_normal:before {
    content: "\f12f";
}
.icon-menu_over:before {
    content: "\f130";
}
.icon-menu_thin:before {
    content: "\f131";
}
.icon-menu:before {
    content: "\f132";
}
.icon-minus-inline:before {
    content: "\f133";
}
.icon-minus-small:before {
    content: "\f134";
}
.icon-paste:before {
    content: "\f135";
}
.icon-paste2:before {
    content: "\f136";
}
.icon-photo-placeholder:before {
    content: "\f137";
}
.icon-play:before {
    content: "\f138";
}
.icon-plus-full:before {
    content: "\f139";
}
.icon-plus-inline:before {
    content: "\f13a";
}
.icon-plus:before {
    content: "\f13b";
}
.icon-preparation_normal:before {
    content: "\f13c";
}
.icon-preparation_over:before {
    content: "\f13d";
}
.icon-preparation_thin:before {
    content: "\f13e";
}
.icon-print:before {
    content: "\f13f";
}
.icon-product_normal:before {
    content: "\f140";
}
.icon-product_over:before {
    content: "\f141";
}
.icon-product_thin:before {
    content: "\f142";
}
.icon-recipe_normal:before {
    content: "\f143";
}
.icon-recipe_over:before {
    content: "\f144";
}
.icon-recipe_thin:before {
    content: "\f145";
}
.icon-remove-small:before {
    content: "\f146";
}
.icon-right-small:before {
    content: "\f147";
}
.icon-right:before {
    content: "\f148";
}
.icon-search:before {
    content: "\f149";
}
.icon-select:before {
    content: "\f14a";
}
.icon-settings:before {
    content: "\f14b";
}
.icon-sort-down:before {
    content: "\f14c";
}
.icon-sort-up:before {
    content: "\f14d";
}
.icon-star-clear:before {
    content: "\f14e";
}
.icon-star-full:before {
    content: "\f14f";
}
.icon-swap_full:before {
    content: "\f150";
}
.icon-swap_outline:before {
    content: "\f151";
}
.icon-toggle-workday:before {
    content: "\f152";
}
.icon-unconfirm:before {
    content: "\f153";
}
.icon-up-small:before {
    content: "\f154";
}
.icon-up:before {
    content: "\f155";
}
.icon-user-full:before {
    content: "\f156";
}
.icon-user:before {
    content: "\f157";
}
