@import "variables";
@import "scss/layout.scss";
@import "scss/general.scss";
@import "scss/forms.scss";

@font-face {
    font-family: 'Nunito-Regular';
    src: url('./assets/fonts/Nunito-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito-Bold';
    src: url('./assets/fonts/Nunito-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito-Italic';
    src: url('./assets/fonts/Nunito-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}
