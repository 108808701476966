@import "variables";

input, textarea, select {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  @include font-normal($blue-text);
  &:focus {
    outline: none;
  }
}

input[type=number] {
  -moz-appearance: textfield;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    opacity: 0;
    -webkit-appearance: none;
    margin: 0;
  }
}

button:focus {
  outline: none;
}

.form-group,
.form-group-hor {
  display: block;

  select,
  input[type=text],
  input[type=email],
  textarea,
  input[type=number] {
    display: block;
    @include font-normal($blue-text);
    font-weight: 500;

    &:focus {
      outline: none;
    }
  }

  input[type=text], input[type=number], input[type=email], textarea {
    padding: 2px 8px;
    border: 2px solid $blue-light;
    @include form-class(border-color);
  }

  select {
    font-size: inherit;
    font-weight: 500;
    background: transparent;
    -webkit-appearance: none;

    border: none;
    border-radius: 0;

    &:focus {
      outline: none;
    }
  }

  ng2-flatpickr.ng-invalid.ng-touched input {
    border-bottom-color: $red-text !important;
  }
}

.form-group {
  input[type=text], input[type=number], input[type=email], textarea {
    width: 100%;
    box-sizing: border-box;
  }
}

.form-group-hor {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  input {
    margin-left: 0.2em !important;
    flex-grow: 1;
    width: 0px;

    &:not(:disabled) {
      color: $blue-text;
      margin-bottom: -2px !important;
    }
  }

  app-select-array {
    margin-left: 0.2em !important;
    flex-grow: 1;
  }

  app-count {
    margin-left: 0.2em !important;
  }

  input {
    &[type=number] {
      text-align: right;
    }

    &[type=text] {
      border: 2px solid $blue-light;
      margin: 0;
      padding: 2px;
    }
  }
}



.form-radio-array {
  label {
    @include font-normal(inherit);
    display: block;
    input:checked+* {
      color: $blue-text;
    }
  }
  label {
    display: flex;
    align-items: flex-start;
  }
  input {
    margin: 3px 5px 0 0;
  }
}

.form-radio-array--label {
  @include font-large(large);
}

.form-radio-array--columns {
  columns: 2;
}

.form-label {
  @include font-label;
  position: relative;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  .help-tooltip {
    margin-left: 10px;
  }
}

.form-title-field {
  display: flex;
  flex-direction: row;
  @include font-large;
  margin-bottom: 40px;

  label {
    display: inline-block;
    @include font-normal;
    margin-left: 15px;
    align-self: flex-end;
    padding-bottom: 7px;

    span {
      color: $blue-text;
    }

    input:checked ~ span {
      @include font-bold;
      color: inherit;
    }
  }

  input {
    color: $blue-text !important;
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 40%;
    @include font-large;
    border: 0;
    border-bottom: 2px solid $blue-light;
    width: 100px;
    margin: 0 0 0 15px;
    padding: 0;
    align-self: flex-start;
    &:focus {
      outline: none;
    }
    font-family: inherit;
    @include form-class(border-bottom-color);
  }

  .help-tooltip {
    margin-left: 5px;
    align-self: center;
  }
}
