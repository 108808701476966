@import "variables";

@function to-string($value) {
  @return inspect($value);
}

@function to-svg-ie-name($name, $color) {
  @return "#{str-slice($name, 1, -5)}.#{str-slice(to-lower-case(to-string($color)), 2)}.svg";
}

@mixin svg-icon($name, $color, $size: contain, $position: center) {
  @supports (-webkit-mask: url("")) or (mask: url("")) {
    background: $color;
    mask: url("~src/assets/icons/#{$name}") no-repeat;
    mask-size: $size;
    mask-position: $position;
  }

  @supports not (mask: url("")) {
    background: url("/assets/icons/ie/#{to-svg-ie-name($name, $color)}") $position no-repeat;
    background-size: $size;
  }

}

@mixin reset-button {
  padding: 0;
  border: 0;
  background: none;
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  text-align: inherit;

  &:focus {
    outline: none;
  }
}

@mixin reset-input {
  padding: 0;
  border: 0;
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  text-align: inherit;

  &:focus {
    outline: none;
  }
}

@mixin reset-ul {
  margin: 0;
  padding: 0;

  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

@mixin close-button($color: $blue-text) {
  border: none;
  background: transparent;
  @include svg-icon('close.svg', $color);
}

@mixin form-class($property) {
  &.ng-invalid.ng-touched {
    #{$property}: $red-text;
  }
}

@mixin action-btn($name, $size:25px, $padding:10px, $color:$blue-text) {
  display: inline-block;
  position: relative;
  padding: ($size + $padding) 3px 3px 3px;
  &:before {
    content: "";
    position: absolute;
    width: $size;
    height: $size;
    left: 50%;
    transform: translate(-50%, 0);
    top: 3px;
    @include svg-icon($name, $color);
  }
  &:disabled:before {
    @include svg-icon($name, $gray-medium);
  }
  background: transparent;
  border: none;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  line-height: 1em;
  @include font-small($color);
  &:disabled {
    color: $gray-medium;
  }
}

@mixin circle-btn($name, $color) {
  display: inline-block;
  width: 100px;
  position: relative;
  text-align: center;
  padding: 60px 0 0 0;
  margin: 0;
  border: 0;
  @include font-small($color);
  text-transform: uppercase;
  background: none;

  &:before, &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 50px;
    height: 50px;
  }

  &:before {
    @include svg-icon($name, $color, 60%);
  }

  &:after {
    border-radius: 50%;
    border: 2px solid $color;
    box-sizing: border-box;
  }
}
@mixin full-circle-btn($name, $color) {
  display: inline-block;
  width: 100px;
  position: relative;
  text-align: center;
  padding: 60px 0 0 0;
  margin: 0;
  border: 0;
  @include font-small($color);
  text-transform: uppercase;
  background: none;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 50px;
    height: 50px;
    @include svg-icon($name, $color);
  }
}

@mixin circle-icon($name, $size, $color, $border: 2px) {
  display: inline-block;
  width: $size;
  height: $size;
  position: relative;
  padding: 0;
  margin: 0;
  border: 0;
  background: none;

  &:before, &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &:before {
    @include svg-icon($name, $color, 60%);
  }

  &:after {
    border-radius: 50%;
    border: $border solid $color;
    box-sizing: border-box;
  }
}

@mixin clear-btn($name, $color) {
  display: inline-flex;
  align-items: center;
  position: relative;
  border: 0;
  background: none;
  font-size: inherit;
  color: $blue-text;
  padding: 0;

  &:before {
    content: "";
    width: 0.8em;
    height: 0.8em;
    margin-right: 0.2em;
    @include svg-icon($name, $blue-text);
  }
}

@mixin dot($size: 8px, $color: $blue-light) {
  content: "";
  position: absolute;
  background: $color;
  width: $size;
  height: $size;
  border-radius: 50%;
}

@mixin size($size) {
  width: $size;
  height: $size;
}

@mixin main-component-layout() {
  margin: 0 auto;
  width: 100%;
  position: relative;

  @each $breakpoint, $max-width in $container-max-widths {
    @media (min-width: map-get($grid-breakpoints, $breakpoint)) {
      max-width: $max-width;
    }
  }
}
